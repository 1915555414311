import { FC } from 'react';
import cn from 'classnames';

import { selectIsLoggedIn, selectIsLoggingIn } from '@rikstv/play-common/src/forces/auth/selectors';
import { menuModalClose } from '@rikstv/play-common/src/forces/ui/ui.slice';

import { useRiksTvDispatch, useRiksTvSelector } from '../../../root.reducer';
import { MyPage, NewCustomer, PageNavigation } from '../header/Header';
import { BackMenuItem, LoginLogoutButton, TvGuideMenuItem } from '../header/menu';

import style from './MobileMenu.module.css';

export const MobileMenu: FC = () => {
  const isLoggingIn = useRiksTvSelector(selectIsLoggingIn);
  const isLoggedIn = useRiksTvSelector(selectIsLoggedIn);
  const dispatch = useRiksTvDispatch();
  return (
    <div className={cn('rtv', style.mobileMenu)}>
      <header className={style.logoContainer}>
        <button
          onClick={() => dispatch(menuModalClose())}
          className={cn('with-keyboard-focus', style.modalCloseButton)}>
          <span />
        </button>
      </header>
      <nav>
        <ul className={style.navigationList}>
          <PageNavigation />
          <TvGuideMenuItem />
        </ul>
        <ul className={style.navigationList}>
          <li>
            <BackMenuItem />
          </li>
        </ul>
        <ul className={cn(style.navigationList)}>
          {isLoggedIn ? <MyPage /> : <NewCustomer />}
          <li>
            <LoginLogoutButton isLoggingIn={isLoggingIn} isLoggedIn={isLoggedIn} />
          </li>
        </ul>
      </nav>
    </div>
  );
};
