import { configEnvs } from '@rikstv/play-common/src/config';
import { envs } from '@rikstv/play-common/src/config/apps/config.utils';

const rtvConfig = {
  signUpUrl: configEnvs.forEnv({
    [envs.PROD]: 'https://www.rikstv.no/tv-pakker/',
    [envs.PT]: 'https://www.rikstv.no/tv-pakker/',
    [envs.TEST]: 'https://www.uat.rikstv.no/tv-pakker/',
    [envs.UAT]: 'https://www.uat.rikstv.no/tv-pakker/',
    [envs.DEV]: 'https://www.uat.rikstv.no/tv-pakker/',
  }),
  signUpRikspakkenUrl: configEnvs.forEnv({
    [envs.PROD]: 'https://www.rikstv.no/kampanje/rikspakken/',
    [envs.PT]: 'https://www.rikstv.no/kampanje/rikspakken/',
    [envs.TEST]: 'https://www.uat.rikstv.no/kampanje/rikspakken/',
    [envs.UAT]: 'https://www.uat.rikstv.no/kampanje/rikspakken/',
    [envs.DEV]: 'https://www.uat.rikstv.no/kampanje/rikspakken/',
  }),
  myPageUrl: configEnvs.forEnv({
    [envs.PROD]: 'https://minside.rikstv.no',
    [envs.PT]: 'https://minside.rikstv.no',
    [envs.TEST]: 'https://minside.uat.rikstv.no',
    [envs.UAT]: 'https://minside.uat.rikstv.no',
    [envs.DEV]: 'https://minside.uat.rikstv.no',
  }),
  integrationsApiUrl: configEnvs.forEnv({
    [envs.PROD]: 'https://integration-gateway-public.rikstv.no/1',
    [envs.PT]: 'https://integration-gateway-public.rikstv.no/1',
    [envs.TEST]: 'https://integration-gateway-public.uat.rikstv.no/1',
    [envs.UAT]: 'https://integration-gateway-public.uat.rikstv.no/1',
    [envs.DEV]: 'https://integration-gateway-public.dev.rikstv.no/1',
  }),
};

export { rtvConfig };
