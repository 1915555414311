import { FC } from 'react';

import { CommonRouter } from '@rikstv/play-common/src/router/CommonRouter';
import { OnRouteChange } from '@rikstv/play-common/src/router/OnRouteChange';

import { LayoutRiksTV } from '../containers/layout/LayoutRiksTV';

import { routerPaths } from './router.path';
import { routes } from './routes';

const RouterComponent: FC = () => (
  <CommonRouter
    routes={routes}
    redirectPath={routerPaths.frontpage}
    brandLayout={LayoutRiksTV}
    onRouteChange={OnRouteChange}
  />
);

export default RouterComponent;
