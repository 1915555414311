import type API from '@rikstv/integrations-api';
import { request } from '@rikstv/play-common/src/forces/utils/request';

import { rtvConfig } from '../config';

const defaultHeaders = Object.fromEntries(
  new Headers({
    Accept: 'application/json',
  }).entries()
);

const get = (path: string, headers: Record<string, string> = {}): Parameters<typeof request>[0] => {
  return {
    path,
    url: rtvConfig.integrationsApiUrl,
    headers: {
      ...defaultHeaders,
      ...headers,
    },
  };
};

export const getCustomerStatus = async (token: Token) => {
  return request<API.CustomerStatusResponse>(get('/customer'), token);
};

type Token = string | null | undefined;
