import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';

import { getCommonMiddleware } from '@rikstv/play-common/src/forces/commonMiddleware';
import { captureExceptionInSentry } from '@rikstv/play-common/src/utils/errorTracker/tracking';

import rootReducer, { RiksTvState } from '../../root.reducer';
import rootSaga from '../../root.saga';
import { analyticsMiddleware } from '../../utils/analytics/analyticsMiddleware';
import { rikstvAnalytics } from '../../utils/analytics/rikstvAnalytics';

const sagaMiddleware = createSagaMiddleware({
  onError: (error, errorInfo) =>
    captureExceptionInSentry(error, { extra: { errorInfo }, fingerprint: ['saga-middleware'] }),
});

const setupStore = (initialState?: Partial<RiksTvState>) => {
  const store = configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware => [
      ...getDefaultMiddleware({ serializableCheck: false }),
      sagaMiddleware,
      analyticsMiddleware,
      ...getCommonMiddleware(rikstvAnalytics),
    ],
    preloadedState: initialState,
  });
  sagaMiddleware.run(rootSaga);

  return store;
};

export default setupStore;
