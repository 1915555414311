import { create } from 'zustand';

import { KnownExternalServiceId } from '@rikstv/play-common/src/forces/externalService/externalServiceIds';
type ExternalServiceStore = Record<KnownExternalServiceId, boolean> & {
  isActivated: (id: KnownExternalServiceId) => boolean;
};

export const useExternalServiceStore = create<ExternalServiceStore>()((set, get) => ({
  max: false,
  tv2play: false,
  nrk: true, // Provider is enabled for everyone
  viaplay: false, // Not available for Play.RiksTV
  // Methods
  isActivated: id => {
    return get()[id] ?? false;
  },
}));
