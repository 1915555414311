import { CommonMiddleware } from '@rikstv/play-common/src/forces/root.reducer';

import { loadedUser } from '../../domain/user/forces/userInfo.slice';

import { rikstvAnalytics } from './rikstvAnalytics';

export const analyticsMiddleware: CommonMiddleware = _store => next => action => {
  if (loadedUser.match(action)) {
    const { userId, userHash, email, products } = action.payload;
    rikstvAnalytics.userLoggedIn(userId, userHash, email, products);
  }
  return next(action);
};
