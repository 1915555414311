import type API from '@rikstv/integrations-api';
import { AccessTokenValue } from '@rikstv/play-common/src/forces/auth/auth.types';
import { decodeToken } from '@rikstv/play-common/src/utils/auth/jwt.utils';

import { getCustomerStatus } from '../../../apis/RiksTVIntegrationsAPI';
import { useExternalServiceStore } from '../externalServiceStore';

import { UserInfo } from './user.types';

const getEmailFromToken = (encrypted: string): string | null | undefined => {
  const decoded = decodeToken(encrypted);
  return decoded.email;
};

const mapToUserInfo = (customerResponse: API.CustomerInfoResponse, accessTokenValue: AccessTokenValue): UserInfo => ({
  userId: customerResponse.customerNumber,
  userHash: customerResponse.userHash,
  email: getEmailFromToken(accessTokenValue),
  products: customerResponse.products,
});

export const getUserInfo = async (accessTokenValue: AccessTokenValue): Promise<UserInfo> => {
  const customerStatusResponse: API.CustomerStatusResponse = await getCustomerStatus(accessTokenValue).catch(() => {
    return {
      max: { isActivated: false, activationUrl: '' },
      tv2Play: { status: 'Unknown' },
      customer: { customerNumber: -1, userHash: '', products: [] },
    } satisfies API.CustomerStatusResponse;
  });

  const { max, tv2Play, customer } = customerStatusResponse;
  const tv2PlayActivated = ['Completed', 'Connected'].includes((tv2Play as API.Tv2PlayStatusResponse)?.status);

  useExternalServiceStore.setState({
    max: max?.isActivated ?? false,
    tv2play: tv2PlayActivated,
  });

  return mapToUserInfo(customer, accessTokenValue);
};
