import { FC, forwardRef, PropsWithChildren } from 'react';
import ResponsiveModal from 'react-responsive-modal';
import classNames from 'classnames';

import { RiksTVProvider, StrimProvider } from '@rikstv/shared-components';
import type { Core } from '@rikstv/shared-components/dist/components/core/Core';

import config from '../../config';
import { CloseIcon } from '../../icons';
import { isIOS } from '../../utils/device/device.utils';

import styles from './Modal.module.css';

interface Props {
  open: boolean;
  className?: string;
  onClose: () => void;
  center?: boolean;
}

// Need to add provider class to modal since close button
// end up outside scope of BrandProvider, and so css variables
type Brand = Parameters<typeof Core>[0]['brand'];
const brand: Brand = config.application === 'RiksTV' ? 'rtv' : 'strm';
const BrandProvider = brand === 'rtv' ? RiksTVProvider : StrimProvider;

export const Modal = forwardRef<HTMLDivElement, PropsWithChildren<Props>>(
  ({ children, open = true, className, onClose, center }, ref) => {
    const modalClassNames: Parameters<typeof ResponsiveModal>[0]['classNames'] = {
      modal: classNames(styles.modal, className, brand),
      overlay: styles.overlay,
      modalContainer: classNames({ [styles.center]: center }),
      closeButton: styles.modalCloseButton,
    };

    const internalRef = typeof ref !== 'function' && ref !== null ? ref : undefined;
    return (
      <ResponsiveModal
        classNames={modalClassNames}
        open={open}
        onClose={onClose}
        blockScroll={!isIOS}
        reserveScrollBarGap={!isIOS}
        initialFocusRef={internalRef}
        closeIcon={<CloseIcon ariaLabel="Lukk" />}>
        <BrandProvider>{children}</BrandProvider>
      </ResponsiveModal>
    );
  }
);

/* Special case for Mobile Menu on Strim/RiksTV, with no animation and hidden close button */
// TODO: mobile menu should not be a modal!!!
export const FullWidthModal: FC<PropsWithChildren<{ open: boolean }>> = ({ children, open }) => {
  const modalClassNames = classNames(styles.modal, styles.fullWidthModal);
  return (
    <ResponsiveModal
      animationDuration={0}
      closeIconId={styles.hiddenModalCloseIcon}
      classNames={{ modal: modalClassNames }}
      open={open}
      onClose={() => void 0}>
      {children}
    </ResponsiveModal>
  );
};
