// load polyfills first
// eslint-disable-next-line simple-import-sort/imports
import '@rikstv/play-common/src/utils/polyfills/polyfill';

import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';

import { ErrorBoundaryAppContainer } from '@rikstv/play-common/src/components/errorBoundary/ErrorBoundaryAppContainer';
import config from '@rikstv/play-common/src/config';
import { CastProvider } from '@rikstv/play-player/src/chromecast/CastProvider';
import { RiksTVProvider } from '@rikstv/shared-components';

import Router from './common/router/Router';
import store from './common/store/store';

import 'normalize.css';
import '@rikstv/shared-components/dist/style.css';
import './assets/styles/global.css';

const rootElement = document.getElementById('root');
if (rootElement == null) {
  throw new Error('rootElement cannot be null');
}

const App = () => (
  <StrictMode>
    <ErrorBoundaryAppContainer>
      <HelmetProvider>
        <Helmet prioritizeSeoTags>
          <title>{config.basePageTitle}</title>
          <meta
            name="description"
            content="Med RiksTV kan du se direkte-TV, film og serier direkte i nettleseren på din PC og Mac, i hele Norge og EU/EØS. Start TV-tittingen her"
          />
          <meta name="apple-mobile-web-app-title" content={config.iosApp.appName} />
          <meta name="apple-itunes-app" content={`app-id=${config.iosApp.appStoreId}`} />
          <meta name="google-play-app" content={config.androidApp.package} />
          <meta property="al:ios:app_name" content={config.iosApp.appName} />
          <meta property="al:ios:app_store_id" content={config.iosApp.appStoreId} />
          <meta
            name="og:description"
            content="Med RiksTV kan du se direkte-TV, film og serier direkte i nettleseren på din PC og Mac, i hele Norge og EU/EØS. Start TV-tittingen her"
          />
          <meta property="og:url" content="https://play.rikstv.no/" />
          <meta property="og:title" content="RiksTV" />
          <meta property="og:image" content="https://play.rikstv.no/fb.jpg" />
        </Helmet>
        <RiksTVProvider>
          <Provider store={store}>
            <CastProvider>
              <Router />
            </CastProvider>
          </Provider>
        </RiksTVProvider>
      </HelmetProvider>
    </ErrorBoundaryAppContainer>
  </StrictMode>
);

const root = createRoot(rootElement);
root.render(<App />);
