import { commonRouterPaths, commonRoutes } from '@rikstv/play-common/src/router/router.path';

export const routerPaths = {
  ...commonRouterPaths,
  login: '/login',
  logout: '/logout',
  logoutCallback: '/logout/:action',
  oAuthCallback: '/app/callback',
} as const;

const commonRouterPathsWithRiksTVPages = {
  ...commonRoutes,
  frontpage: () => routerPaths.frontpage,
};

export default commonRouterPathsWithRiksTVPages;
