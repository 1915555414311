import type { CommonAnalytics } from '@rikstv/play-common/src/utils/analytics/CommonAnalytics.types';
import { commonGoogleTagManager, push } from '@rikstv/play-common/src/utils/analytics/googleTagManager';

export const rikstvAnalytics = {
  ...commonGoogleTagManager,
  userLoggedIn: (id: number, hash: string, email: string | null | undefined, products: string[]) => {
    push({
      uid: hash,
      visitorLoginState: 'yes',
      userProducts: products ? products.join() : '',
    });
  },
  userLoggedOut: () => {
    push({
      visitorLoginState: 'no',
    });
  },
} satisfies CommonAnalytics & Record<string, unknown>;
