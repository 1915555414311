import { lazy } from 'react';

import { RedirectToDefaultPage } from '@rikstv/play-common/src/components/pages/RedirectToDefaultPage';
import { AppRoutes, ROUTE_TYPE_INTERNAL_REDIRECT, ROUTE_TYPE_NORMAL } from '@rikstv/play-common/src/router/routes.type';

import { LayoutRiksTV } from '../containers/layout/LayoutRiksTV';

import { routerPaths } from './router.path';

const OAuthCallback = lazy(() =>
  import('../containers/oauthCallback/OAuthCallback').then(mod => ({ default: mod.OAuthCallback }))
);
const NotFound = lazy(() =>
  import('../components/notFound/NotFound').then(module => ({
    default: module.NotFound,
  }))
);
const DevPage = lazy(() =>
  import('@rikstv/play-common/src/pages/DevPage/DevPage').then(module => ({
    default: module.DevPage,
  }))
);
const TvGuide = lazy(() =>
  import('@rikstv/play-common/src/components/tvGuide-v2/TVGuide').then(module => ({
    default: module.TvGuide,
  }))
);
const SearchPage = lazy(() =>
  import('@rikstv/play-common/src/pages/search/SearchPage').then(module => ({
    default: module.SearchPage,
  }))
);
const CoverPageFactory = lazy(() =>
  import('../components/coverPageFactory/CoverPageFactory').then(module => ({
    default: module.CoverPageFactory,
  }))
);

export const routes: AppRoutes = [
  {
    type: ROUTE_TYPE_NORMAL,
    path: routerPaths.frontpage,
    component: RedirectToDefaultPage,
    layout: LayoutRiksTV,
    waitForStartup: true,
  },
  {
    type: ROUTE_TYPE_NORMAL,
    path: routerPaths.search,
    component: SearchPage,
    layout: LayoutRiksTV,
    waitForStartup: true,
  },
  {
    type: ROUTE_TYPE_NORMAL,
    path: routerPaths.oAuthCallback,
    component: OAuthCallback,
    layout: LayoutRiksTV,
    waitForStartup: false,
  },
  {
    type: ROUTE_TYPE_NORMAL,
    path: [
      /* Old cover pages */
      routerPaths.watchPattern,
      routerPaths.watchPatternWithName,
      /* New super cover page*/
      routerPaths.singleProgramOrMovieWithTitle,
      routerPaths.singleProgramOrMovie,
      /* New SERIES super cover page*/
      routerPaths.stackedSeriesWithTitle,
      routerPaths.stackedSeries,
    ],
    component: CoverPageFactory,
    layout: LayoutRiksTV,
    waitForStartup: true,
  },
  {
    type: ROUTE_TYPE_NORMAL,
    path: [routerPaths.tvGuide, routerPaths.tvGuideWithDate],
    component: TvGuide,
    layout: LayoutRiksTV,
    waitForStartup: true,
  },
  {
    type: ROUTE_TYPE_INTERNAL_REDIRECT,
    path: '/live',
    redirectPath: routerPaths.tvGuide,
  },
  {
    type: ROUTE_TYPE_NORMAL,
    path: '/dev/null',
    component: DevPage,
    layout: LayoutRiksTV,
    waitForStartup: true,
  },
  // "404", catches all unknown paths.
  {
    type: ROUTE_TYPE_NORMAL,
    path: '*',
    component: NotFound,
    layout: LayoutRiksTV,
    waitForStartup: true,
  },
];
