import React, { FunctionComponent } from 'react';
import classNames from 'classnames';

import { HeaderTheme, THEME_DARK } from '@rikstv/play-common/src/forces/ui/constants';
import { menuModalOpen } from '@rikstv/play-common/src/forces/ui/ui.slice';
import { Link } from '@rikstv/play-common/src/router/Link';
import { authService } from '@rikstv/play-common/src/utils/auth/AuthService';

import { useRiksTvDispatch } from '../../../root.reducer';
import routerPath, { routerPaths } from '../../router/router.path';
import { RTVLogo } from '../rtvLogo/RTVLogo';

import { Login, MyPage, NewCustomer } from './Header';

import style from './Header.module.css';

interface MobileMenuItemOwnProps {
  isLoggingIn: boolean;
  isLoggedIn: boolean;
  headerTheme: HeaderTheme;
}

type MobileMenuItemProps = MobileMenuItemOwnProps;

const LoginButton = () => (
  <Link
    className={style.buttonMenu}
    to="#login"
    onClick={e => {
      e.preventDefault();
      authService.login();
    }}>
    Logg inn
  </Link>
);
const LogoutButton = () => (
  <Link
    className={style.buttonMenu}
    to="#logout"
    onClick={e => {
      e.preventDefault();
      authService.logout();
    }}>
    Logg ut
  </Link>
);

interface LoginLogoutProps {
  isLoggedIn: boolean;
  isLoggingIn: boolean;
}

export const LoginLogoutButton: FunctionComponent<LoginLogoutProps> = ({ isLoggingIn, isLoggedIn }) => {
  if (isLoggingIn) {
    return (
      <span style={{ visibility: 'hidden' }}>
        <LogoutButton />
      </span>
    );
  } else if (isLoggedIn) {
    return <LogoutButton />;
  } else {
    return <LoginButton />;
  }
};
// fjerne denne
export const BackMenuItem = () => {
  return (
    <Link className={style.buttonMenu} to="https://www.rikstv.no/" title="Tilbake til RiksTV.no">
      <i />
      RiksTV.no
    </Link>
  );
};

export const SearchMenuItem = React.memo(() => (
  <li className={style.buttonSearchIcon}>
    <MenuButton to={routerPath.search()} className={style.buttonSearch} data-cy="menu-search">
      <span>Søk</span>
    </MenuButton>
  </li>
));

export const SearchIconMenuItem = React.memo(() => (
  <li className={style.buttonSearchIcon}>
    <MenuButton
      to={routerPath.search()}
      className={style.buttonSearch + ' with-keyboard-focus'}
      title="Finn noe å se på"
      data-cy="menu-searchicon">
      <span>Søk</span>
    </MenuButton>
  </li>
));

export const MobileMenuBar = ({ isLoggedIn, headerTheme }: MobileMenuItemProps) => {
  const dispatch = useRiksTvDispatch();
  const handleMobileMenuClick = () => dispatch(menuModalOpen());
  return (
    <div className={style.mobile}>
      <div className={style.mobileButton}>
        <button
          onClick={handleMobileMenuClick}
          className={`with-keyboard-focus ${style.buttonMenuMobile}`}
          title="Åpne meny">
          <span />
        </button>
      </div>
      <RTVLogo className={style.mobileRtvLogo} light={headerTheme === THEME_DARK} />
      <nav className={style.mobileNav}>
        <ul>
          <SearchMenuItem />
          {isLoggedIn ? <MyPage /> : <NewCustomer />}
          {!isLoggedIn ? <Login /> : ''}
        </ul>
      </nav>
    </div>
  );
};

export const TvGuideMenuItem = () => {
  return (
    <li>
      <MenuButton className="with-keyboard-focus" to={routerPaths.tvGuide}>
        TV-guide
      </MenuButton>
    </li>
  );
};

export const MenuButton = React.memo(({ to, className, children, ...props }: Parameters<typeof Link>[0]) => (
  <Link
    to={to}
    className={classNames(style.buttonMenu, className)}
    activeClassName={style.buttonSelected}
    withDefaultStyle={false}
    {...props}>
    {children}
  </Link>
));
