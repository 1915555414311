import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { serviceMaxActivated } from '@rikstv/play-common/src/forces/webSocket/actions';

import { useExternalServiceStore } from '../externalServiceStore';

import { UserInfo } from './user.types';

interface UserInfoState {
  userInfo?: UserInfo;
}

const initialState: UserInfoState = {};

const userInfoSlice = createSlice({
  name: 'userInfoSlice',
  initialState,
  reducers: {
    loadedUser: (state, { payload }: PayloadAction<UserInfo>) => {
      state.userInfo = payload;
    },
  },
  extraReducers: builder =>
    builder.addCase(serviceMaxActivated.type, _state => {
      useExternalServiceStore.setState({ max: true });
    }),
});

export const { loadedUser } = userInfoSlice.actions;

export default userInfoSlice.reducer;
