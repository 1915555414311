import { Link } from '@rikstv/play-common/src/router/Link';
import { getYearFormatted } from '@rikstv/play-common/src/utils/date/date.utils';
import { LightMode, Meta } from '@rikstv/shared-components';

import './Footer.module.css';
import style from './Footer.module.css';

export const Footer = () => (
  <footer className={style.footerWrapper}>
    <div className={style.container}>
      <nav className={style.navigation}>
        <LightMode>
          <ul>
            <li>
              <Link to="https://www.rikstv.no/personvern/" title="Personvern">
                Personvern
              </Link>
            </li>
            <li>
              <Link
                to="https://www.rikstv.no/prisliste-og-vilkaar/abonnementsvilkaar-privatkunde/"
                title="Prisliste og vilkår">
                Vilkår
              </Link>
            </li>
            <li>
              <Link to="https://www.rikstv.no/om-rikstv/jobb-hos-oss/" title="Jobb her">
                {' '}
                Jobb her
              </Link>
            </li>
            <li>
              <Link to="https://www.rikstv.no/kontakt/" title="Kontakt oss">
                Kontakt oss
              </Link>
            </li>
          </ul>
        </LightMode>
        <Meta className={style.copyright}>{getYearFormatted()} &copy; RiksTV AS. Alle rettigheter</Meta>
      </nav>
    </div>
  </footer>
);
