import { FC } from 'react';
import classNames from 'classnames';

import { usePages } from '@rikstv/play-common/src/components/pages/usePages';
import { Link } from '@rikstv/play-common/src/router/Link';

import routerPath from '../../router/router.path';

import style from './RTVLogo.module.css';

interface Props {
  light: boolean;
  className?: string;
}

export const RTVLogo: FC<Props> = ({ className, light = false }) => {
  const { defaultPage } = usePages();

  return (
    <Link
      to={defaultPage ? `/${defaultPage.slug}` : routerPath.frontpage()}
      className={classNames(style.logo, className, {
        [style.light]: light,
      })}
    />
  );
};
