import React, { FunctionComponent } from 'react';
import classNames from 'classnames';

import { usePages } from '@rikstv/play-common/src/components/pages/usePages';
import { SkipLink } from '@rikstv/play-common/src/components/skipLink/SkipLink';
import { HeaderTheme, THEME_DARK } from '@rikstv/play-common/src/forces/ui/constants';
import { Link } from '@rikstv/play-common/src/router/Link';
import { authService } from '@rikstv/play-common/src/utils/auth/AuthService';
import { captureExceptionInSentry } from '@rikstv/play-common/src/utils/errorTracker/tracking';

import { rtvConfig } from '../../../config';
import { RTVLogo } from '../rtvLogo/RTVLogo';

import { MobileMenuBar, SearchIconMenuItem, TvGuideMenuItem } from './menu';

import style from './Header.module.css';

interface Props {
  isLoggedIn: boolean;
  isLoggingIn: boolean;
  headerTheme: HeaderTheme;
}

export const Header: FunctionComponent<Props> = props => {
  const { isLoggedIn, isLoggingIn, headerTheme } = props;
  const isDarkTheme = headerTheme === THEME_DARK;
  return (
    <header
      className={classNames(style.header, {
        [style.dark]: isDarkTheme,
      })}>
      <SkipLink elementId="main" buttonText="Hopp til hovedinnholdet" />
      <div className={style.row}>
        <nav className={style.desktopNav}>
          <ul>
            <li className={style.rtvLogoWrapper}>
              <RTVLogo className={style.rtvLogo} light={isDarkTheme} />
            </li>
            <PageNavigation />
            <TvGuideMenuItem />
          </ul>
        </nav>
        <nav className={style.desktopNav}>
          <ul>
            <SearchIconMenuItem />
            {isLoggedIn ? (
              <>
                <Logout />
                <MyPage />
              </>
            ) : (
              <>
                <NewCustomer />
                <Login />
              </>
            )}
          </ul>
        </nav>
        <MobileMenuBar isLoggingIn={isLoggingIn} isLoggedIn={isLoggedIn} headerTheme={headerTheme} />
      </div>
    </header>
  );
};

export const MyPage = () => {
  return (
    <li className={style.myAccountItem}>
      <Link className={style.buttonMenu} to={rtvConfig.myPageUrl} title="Min side">
        Min side
      </Link>
    </li>
  );
};

export const NewCustomer = () => {
  return (
    <li className={style.signUpItem}>
      <Link className={style.buttonMenu} to={rtvConfig.signUpUrl} title="Bli kunde - våre TV-pakker">
        Bli kunde
      </Link>
    </li>
  );
};

export const Login = () => {
  const handleClick: React.MouseEventHandler<HTMLAnchorElement> = e => {
    e.preventDefault();
    authService.login();
  };
  return (
    <li className={style.logInOutItem}>
      <Link to="#login" onClick={handleClick} className={style.buttonMenu}>
        Logg inn
      </Link>
    </li>
  );
};

export const Logout = () => {
  const handleClick: React.MouseEventHandler<HTMLAnchorElement> = e => {
    e.preventDefault();
    authService.logout();
  };
  return (
    <li className={style.logInOutItem}>
      <Link to="#logout" onClick={handleClick} className={style.buttonMenu}>
        Logg ut
      </Link>
    </li>
  );
};

export const PageNavigation: React.FC = () => {
  const { pages, error } = usePages();

  if (error) {
    captureExceptionInSentry(error);
  }

  if (!pages || error) {
    return null;
  }

  return (
    <>
      {pages.map(page => (
        <li key={page.id}>
          <Link
            to={`/${page.slug}`}
            className={classNames(style.buttonMenu, 'with-keyboard-focus')}
            activeClassName={style.buttonSelected}
            withDefaultStyle={false}>
            <span className="sr-only">Gå til </span>
            {page.name}
          </Link>
        </li>
      ))}
    </>
  );
};
