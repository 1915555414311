import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router';

import { usePages } from './usePages';

export const RedirectToDefaultPage: FC = () => {
  const { defaultPage } = usePages();

  const navigate = useNavigate();

  useEffect(() => {
    defaultPage && navigate(`/${defaultPage.slug}`, { replace: true });
  }, [defaultPage, navigate]);

  return null;
};
